import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { Base } from 'src/components/layouts/Base';
import { Link } from 'src/components/links/Link';
import { EpisodeQuery } from 'src/graphqlTypes';

interface EpisodeProps {
    data: EpisodeQuery;
}

const Episode: FC<EpisodeProps> = ({ data }) => {
    return (
        <Layout>
            <Base spacing={2}>Episode {data.contentfulEpisode?.episodeNumber}</Base>
            {data.contentfulEpisode?.pitches?.map((pitch) => (
                <div key={pitch?.id}>
                    <Link to={`/${pitch?.slug}`}>{pitch?.pitchTitle}</Link>
                </div>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Episode($episodeNumber: Int!) {
        contentfulEpisode(episodeNumber: { eq: $episodeNumber }) {
            id
            episodeNumber
            pitches {
                id
                slug
                pitchTitle
            }
        }
    }
`;

export default Episode;
